<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>웨딩 장소 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="title">장소명</option>
          <option value="region">지역</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label>
          <input type="radio" value="HALL" v-model="category" />
          웨딩홀
        </label>
        <label>
          <input type="radio" value="HOTEL" v-model="category" />
          호텔
        </label>
        <label>
          <input type="radio" value="HOUSE" v-model="category" />
          스몰/야외&amp;하우스웨딩
        </label>
        <!-- <label>
          <input type="radio" value="OUTDOOR" v-model="category" />
          야외 웨딩
        </label> -->
        <label>
          <input type="radio" value="MEETING" v-model="category" />
          상견례
        </label>
        <label>
          <input type="radio" value="BIRTHDAY" v-model="category" />
          돌잔치
        </label>
        <label>
          <input type="radio" value="TRADITION" v-model="category" />
          전통 혼례
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">
        웨딩 장소 리스트
        <button class="right basic">
          <router-link to="/admin/placemanage/register"
            >웨딩 장소 등록</router-link
          >
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 13%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
          <col style="width: 12%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <th>장소명</th>
          <th>지역</th>
          <th>식사</th>
          <th>예식종류</th>
          <th class="right">식대</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in placeList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>
            {{
              data.category == "HALL"
                ? "웨딩홀"
                : data.category == "HOTEL"
                ? "호텔"
                : data.category == "HOUSE"
                ? "스몰/야외&amp;하우스웨딩"
                : data.category == "MEETING"
                ? "상견례"
                : data.category == "BIRTHDAY"
                ? "돌잔치"
                : data.category == "TRADITION"
                ? "전통 혼례"
                : ""
            }}
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>{{ data.region }}</td>
          <td>
            <span v-for="(item, j) in data.foodType" :key="j">
              {{
                item == "KOREAN"
                  ? "한식"
                  : item == "WESTERN"
                  ? "양식"
                  : item == "CHINESE"
                  ? "중식"
                  : item == "BUFFET"
                  ? "뷔페"
                  : ""
              }}</span
            >
          </td>
          <td>
            <span v-for="(item, k) in data.ceremonyType" :key="k">
              {{
                item == "SEPARATE"
                  ? "분리"
                  : item == "SINGLE"
                  ? "단독"
                  : item == "SAMETIME"
                  ? "동시"
                  : item == "ETC"
                  ? "기타"
                  : ""
              }}</span
            >
          </td>
          <td class="right">
            {{ data.fee ? numberFormat(data.fee) + "원" : "-" }}
          </td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="placeList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchWeddingPlaceList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "toymanage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      placeList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    this.getPlaceList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.placeList = [];
      this.total = 0;
      this.getPlaceList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getPlaceList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPlaceList();
    },
    handleMove(id) {
      this.$router.push({ name: "placeRegister", query: { id: id } });
    },
    getPlaceList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
      };
      fetchWeddingPlaceList(params).then((res) => {
        if (res.data.status == 200) {
          this.placeList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
